@use "./variables" as v;


/* Custom Styles */
.btn-primary {
    color: white;
    box-shadow: 1px 1px 3px black;
    border:0;
}





/********** Custom CSS ************/
h1,
h2,
.font-weight-bold {
    font-weight: 700 !important;
}

h3,
h4,
.font-weight-semi-bold {
    font-weight: 600 !important;
}

h5,
h6,
.font-weight-medium {
    font-weight: 500 !important;
}

.btn {
    font-family: 'Jost', sans-serif;
}

.btn-square {
    width: 36px;
    height: 36px;
}

.btn-sm-square {
    width: 28px;
    height: 28px;
}

.btn-lg-square {
    width: 46px;
    height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.btn-icon {
    width: 77px;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 30px;
    z-index: 11;
}

.navbar-light .navbar-nav .nav-link {
    font-family: 'Jost', sans-serif;
    padding: 30px 15px;
    font-size: 18px;
    font-weight: 500;
    color: $dark;
    outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
    color: $primary;
}

@media (max-width: 991.98px) {
    .navbar-light .navbar-nav .nav-link  {
        padding: 10px 15px;
    }
}

.jumbotron {
    background: linear-gradient(v.$color4, v.$color5), url(../img/header.jpg), no-repeat center center;
    background-size: cover;
}

.jumbotron.page-header {
    background: linear-gradient(v.$color4, v.$color5), url(../img/page-header.jpg), no-repeat center center;
    background-size: cover;
}

.input-group-append {

    .btn:disabled {
        background-color: v.$color2 !important;
        opacity:1;
        display:none;
    }
    
}

.overlay-top::before,
.overlay-bottom::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 85px;
    left: 0;
    z-index: 1;
}

.overlay-top::before {
    top: 0;
    background: url(../img/overlay-top.png) top center no-repeat;
    background-size: contain;
}

.overlay-bottom::after {
    bottom: 0;
    background: url(../img/overlay-bottom.png) bottom center no-repeat;
    background-size: contain;
}

.bg-image {
    background: linear-gradient(rgba(40, 120, 235, 0.05), rgba(40, 120, 235, 0.05)), url(../img/bg-image.jpg);
    background-attachment: fixed;
}

.section-title h6::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    border-top: 2px dashed $secondary;
}

.courses-item img {
    position: relative;
    margin-top: 40px;
    transition: .5s;
}

.courses-list-item img {
    position: relative;
    transition: .5s;
}

.courses-item .courses-text,
.courses-list-item .courses-text {
    position: absolute;
    width: 100%;
    height: calc(100% + 86px);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(to bottom, rgba(18, 15, 45, 0), rgba(18, 15, 45, 1));
    transition: .5s;
    z-index: 1;
}

.courses-list-item .courses-text {
    height: 100%;
}

.courses-item:hover img {
    margin-top: 0px;
}

.courses-list-item:hover img {
    transform: scale(1.2);
}

.courses-item:hover .courses-text {
    height: calc(100% + 40px);
}

.team-carousel .owl-nav,
.related-carousel .owl-nav {
    position: absolute;
    width: 100%;
    height: 60px;
    top: calc(50% - 30px);
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.team-carousel .owl-nav .owl-prev,
.team-carousel .owl-nav .owl-next,
.related-carousel .owl-nav .owl-prev,
.related-carousel .owl-nav .owl-next {
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: $primary;
    font-size: 22px;
    transition: .5s;
}

.team-carousel .owl-nav .owl-prev:hover,
.team-carousel .owl-nav .owl-next:hover,
.related-carousel .owl-nav .owl-prev:hover,
.related-carousel .owl-nav .owl-next:hover {
    background: $secondary;
}

.testimonial-carousel .owl-item img {
    width: 80px;
    height: 80px;
}

.testimonial-carousel .owl-nav {
    position: absolute;
    width: 120px;
    height: 60px;
    top: 0;
    right: 0;
    display: flex;
    z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: $primary;
    font-size: 22px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next:hover {
    background: $secondary;
}

.testimonial-carousel .owl-nav .owl-prev:hover {
    background: $primary;
}

.lib-container {
    
}
